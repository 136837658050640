import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


import { AppComponent } from '../components/app.component';
import { LoginComponent } from '../components/login.component';
import { HomeComponent } from '../components/home.component';
import { LoadingComponent } from '../components/loading.component';
import { RankingComponent } from '../components/ranking.sub.component';
import { ChartComponent } from '../components/chart.sub.component';

import { AppService } from '../services/app.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    RankingComponent,
    ChartComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }
