import { Component, ViewChild, ElementRef, OnInit, Input, AfterViewInit } from '@angular/core';
import { AppService } from '../services/app.service';
import * as _ from 'underscore';
import * as $ from 'jquery';

@Component({
  selector: '[chart]',
  templateUrl: '../templates/chart.sub.component.html',
  styleUrls: ['../styles/chart.sub.component.less']
})

export class ChartComponent implements OnInit, AfterViewInit {

	@Input() data1: Array<any>;
	@Input() data2: Array<any>;
	@Input() data3: Array<any>;
	@Input() color1: string;
	@Input() color2: string;
	@Input() color3: string;

	@Input() options: any;
	@Input() identifier: string;
	@Input() type: string;
	@Input() cheight: number = null;
	
	private google: any;
	private width: number;
	private resize: number;
	public resizing: boolean = false;

	constructor(private service: AppService) {};

	ngOnInit(): void {
		this.google = (<any>window).google.visualization;
	}

	ngAfterViewInit() {
		this.initialize(true);
	}

	private initialize = (init: boolean) => {
		if(!(<any>window).__GCREADY__) {
			setTimeout(this.initialize, 250);
			return;
		}

		this.resizing = false;

		setTimeout(() => {
			this.width = $(`#${this.identifier}`).parent().width();
			if(this.type === "area") this.drawAreaChart();
			if(this.type === "line") this.drawLineChart();
			if(this.type === "bar") this.drawBarChart();
			if(this.type === "histogram") this.drawHistogram();
			if(init) $(window).on("resize", this.onWindowResize);
		}, 10);
	}

	private onWindowResize = (e) => {
		this.resizing = true;
		clearTimeout(this.resize);
		this.resize = setTimeout(this.initialize, 100);
	};

	private drawAreaChart() {
		let data = this.google.arrayToDataTable([["",""]].concat(this.data1));
		let options = {width: this.width, height: this.cheight, colors: [this.color1], hAxis: {slantedText: true}, vAxis: {minValue: 0}, legend: {position: "none"}, chartArea: {left: "7%", width: "86%"}};
		let chart = new this.google.AreaChart(document.getElementById(this.identifier));
		chart.draw(data,options);
	}

	private drawLineChart() {
		let data1 = _.isEmpty(this.data1) ? [] : [...this.data1];
		let data2 = _.isEmpty(this.data2) ? [] : [...this.data2];
		let data3 = _.isEmpty(this.data3) ? [] : [...this.data3];
		let combined = [["","","",""]].concat(data1.concat());
		let index = 1;
		let label, value, ciIndex, item;
		let data, options, chart;
		

		//Note: if the months get too scattered, we might need to pull down a date stamp for sorting after insertion.

		_.each(data2, (element) => {
			label = element[0];
			value = element[1];
			ciIndex = this.fetchItemIndex(label, combined);
			item = ciIndex === -1 ? [label, 0] : combined[ciIndex];
			item[2] = value;

			if(ciIndex === -1) combined.splice(index,0, item);
			index = combined.indexOf(item) + 1;
		});


		_.each(data3, (element,index) => {
			label = element[0];
			value = element[1];
			ciIndex = this.fetchItemIndex(label, combined);
			item = ciIndex === -1 ? [label, 0, 0] : combined[ciIndex];
			if(item.length === 2) item.push(0);
			item[3] = value;

			if(ciIndex === -1) combined.splice(index,0, item);
			index = combined.indexOf(item) + 1;
		});

		data = this.google.arrayToDataTable(combined);
		options = {width: this.width, height: this.cheight, colors: [this.color1, this.color2, this.color3], hAxis: {slantedText: true}, vAxis: {minValue: 0}, legend: {position: "none"}, chartArea:  {left: "7%", width: "86%"}};
		chart = new this.google.LineChart(document.getElementById(this.identifier));
		chart.draw(data,options);
	}

	private drawBarChart() {

		this.data1.forEach((e,i) => {
			if(i === 0) e[2] = { role: 'style' };
			else e[2] = this["color" + (i)];
		});

		let data = this.google.arrayToDataTable(this.data1);
		let options = {width: this.width, height: this.cheight, legend: {position: "none"}, chartArea:  {left: "7%", width: "86%"}};
		let chart = new this.google.ColumnChart(document.getElementById(this.identifier));
		chart.draw(data,options);
	}

	private drawHistogram() {
		let converted = this.data1.map(e => [e[0].toString(), e[1]]);
		let data = this.google.arrayToDataTable(converted);
		let options = {width: this.width, height: this.cheight, colors: [this.color1], hAxis: {type: 'category'},
						vAxis: {minValue: 0}, histogram: null, legend: {position: "none"}, chartArea:  {left: "7%", width: "86%"}};
		let chart = new this.google.Histogram(document.getElementById(this.identifier));
		options.histogram = this.options;
		chart.draw(data,options);
	}

	private fetchItemIndex(label, source): number {
		for(let i = 0, count = source.length; i < count; i++) {
			let index = source[i].indexOf(label);
			if(index !== -1) return i;
		}
		return -1;
	}
}
