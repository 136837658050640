import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse, HttpRequest, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, Subject, BehaviorSubject, throwError, of} from 'rxjs';
import {catchError, tap} from "rxjs/operators";
import * as _ from 'underscore';
import $ from "jquery";

@Injectable({
	 providedIn: 'root'
})
export class AppService {

  public TRANSITION_SPEED = 250;
	public model = <any>{};
  public startDate = null;
  public endDate = null;

	private requestedPageSource:Subject<string> = new BehaviorSubject<string>(null);
  public requestedPage$ = this.requestedPageSource.asObservable();

  private showLoader:Subject<boolean> = new Subject<boolean>();
  public showLoader$ = this.showLoader.asObservable();



	constructor(private http: HttpClient) {
    this.http.post<any>("server/services/AppService.php", {request: "authenticate", data: {}}).pipe(
      catchError(this.handleError)
    ).subscribe(this.initialize);
  }

  public login(data) {
    return this.http.post<any>("server/services/AppService.php", {request: "login", data: data}).pipe(
      tap((response) => this.model = response),
      catchError(this.handleError)
    );
  }

  public logout() {
    return this.http.post<any>("server/services/AppService.php", {request: "logout", data: {}}).pipe(
      catchError(this.handleError)
    ).subscribe(e => {
      window.location.reload();
    });
  }

  public load(data) {
    this.setCurrentPage("PAGE_LOADING");

    this.startDate = data.startDate;
    this.endDate = data.endDate;

    this.http.post<any>("server/services/AppService.php", {request: "load", data: data})
        .pipe( catchError(this.handleError) )
        .subscribe(e => {
          this.model = e;
          this.setCurrentPage("PAGE_HOME");
        });
  }

  private handleError(error: HttpErrorResponse) {
    return throwError('HttpResponseError');
  }

  public setCurrentPage(page: string) {
    this.requestedPageSource.next(page);
  }

  private initialize = (e) => {
    this.model = e;

    console.log(this.model);

    if(e.authenticated !== true) {
      this.setCurrentPage("PAGE_LOGIN");
    } else {
      this.setCurrentPage("PAGE_HOME");
    }
  }
}