import { Component, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { AppService } from '../services/app.service';
import {NgForm} from '@angular/forms';
import * as $ from 'jquery';
import * as _ from 'underscore';
import '../../../node_modules/jquery-ui-dist/jquery-ui.min.js';

@Component({
  selector: '[home]',
  templateUrl: '../templates/home.component.html',
  styleUrls: ['../styles/home.component.less']
})
export class HomeComponent implements OnInit, AfterViewInit {

	public model: any;
	public dateinfo: any = {};
	public today: string;

	private $start:any;
	private $end:any;

	constructor(private service: AppService) {};
	
	ngOnInit(): void {
		let today = new Date();
		let month: string | number = today.getMonth() + 1;
		month = month < 10 ? `0${month}`: month;

		this.model = this.service.model;
		this.dateinfo.start = this.service.startDate;// || `${month}/01/${today.getFullYear()}`;
		this.dateinfo.end = this.service.endDate;

		this.today = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;

		this.model.userProgress = [["",""],["Total Users", this.model.totalUsersAllTime],
												["Completed Intro Quiz", this.model.totalGTKYCompletions],
												["Completed Entire App", this.model.totalCompletions]];
	}

	ngAfterViewInit() {
		this.$start = $("#start-date");
		this.$end = $("#end-date");
		this.$start.datepicker({changeMonth: true, changeYear: true, onClose: this.onCloseStartDate, onChangeMonthYear: this.onSetStartDate});
		this.$end.datepicker({changeMonth: true, changeYear: true, onClose: this.onCloseEndDate, onChangeMonthYear: this.onSetEndDate});
	}

	logout() {
		this.service.logout();
	}

	onSubmit(f: NgForm) {
		let start = this.$start.val();
		let end = this.$end.val();
		
		if(_.isEmpty(start)) start = null;
		if(_.isEmpty(end)) end = null;

		this.service.load({startDate: start, endDate: end});
	}

	private onSetStartDate = (year, month) => {
		month = month < 10 ? `0${month}`: month;
		this.dateinfo.start = `${month}/01/${year}`;
	};

	private onSetEndDate = (year, month) => {
		month = month < 10 ? `0${month}`: month;
		this.dateinfo.end = `${month}/01/${year}`;
	};

	private onCloseStartDate = (dateText) => {
		if(!_.isEmpty(dateText)) return;

		let today = new Date();
		let month: string | number = today.getMonth() + 1;
		month = month < 10 ? `0${month}`: month;
		this.dateinfo.start = `${month}/01/${today.getFullYear()}`;
	};

	private onCloseEndDate = (dateText) => {
		if(!_.isEmpty(dateText)) return;

		let today = new Date();
		let month: string | number = today.getMonth() + 1;
		month = month < 10 ? `0${month}`: month;
		this.dateinfo.end = `${month}/01/${today.getFullYear()}`;
	};
}
