import { Component, ViewChild, ElementRef, OnInit, Input } from '@angular/core';
import { AppService } from '../services/app.service';

@Component({
  selector: '[loading]',
  templateUrl: '../templates/loading.component.html',
  styleUrls: ['../styles/loading.component.less']
})

export class LoadingComponent implements OnInit {

	constructor(private service: AppService) {};

	ngOnInit(): void {}
}
