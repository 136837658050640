import { Component, ViewChild, ElementRef, OnInit, Input } from '@angular/core';
import { AppService } from '../services/app.service';

@Component({
  selector: '[ranking]',
  templateUrl: '../templates/ranking.sub.component.html',
  styleUrls: ['../styles/ranking.sub.component.less']
})

export class RankingComponent implements OnInit {

	@Input() items: Array<any>;
	@Input() header1: string;
	@Input() header2: string;
	@Input() color: string;
	
	public model: any;

	constructor(private service: AppService) {};

	ngOnInit(): void {
	}
}
