import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: '[login]',
  templateUrl: '../templates/login.component.html',
  styleUrls: ['../styles/login.component.less']
})
export class LoginComponent implements OnInit {

	constructor(private service: AppService) {};

	public userInfo: any = {username: null, password: null};
	
	ngOnInit(): void {
		//temp

	}

	login(f: NgForm) {
		//08/14/2018
		//08/28/2018
		this.service.login({username:f.form.value.username, 
								password:f.form.value.password,
								startDate: null,
								endDate: null}).subscribe(e => {
			

			if(this.service.model.authenticated === true) {
				this.service.setCurrentPage("PAGE_HOME");
			} else {
				alert(`Login Error: ${this.service.model.error}`);
				//temp 
				/*
				this.service.model.usersHistogram = e.usersHistogram;
				this.service.model.gamesHistogram = e.averageGamesPerUserHistogram;
				console.log("database response");
				console.log(e);
				
				var data = (<any>window).google.visualization.arrayToDataTable([
		          ['Month & Year', 'Users']].concat(this.service.model.usersHistogram)
		         );

		        var options = {
		          title: 'Total Users',
		          hAxis: {title: 'Month',  titleTextStyle: {color: '#333'}},
		          vAxis: {minValue: 0}
		        };

		        var chart = new (<any>window).google.visualization.AreaChart(document.getElementById('users-chart'));
		        chart.draw(data, options);
		        

		        var data = (<any>window).google.visualization.arrayToDataTable([
		          ['Month & Year', 'Games Per User']].concat(this.service.model.gamesHistogram)
		         );

        		var options = {
				title: 'Average Games Per User',
				legend: { position: 'none' },

				histogram: {
					bucketSize: 1,
				},
				hAxis: {
					minValue: 1,
					viewWindow: {min: 1}
				}};

		        var chart = new (<any>window).google.visualization.Histogram(document.getElementById('games-chart'));
		        chart.draw(data, options);*/
			}
		});
	}
}
