import { Component, ViewChild, ElementRef, OnInit, enableProdMode } from '@angular/core';
import { AppService } from '../services/app.service';

//enableProdMode();

@Component({
  selector: '[app-container]',
  templateUrl: '../templates/app.component.html',
  styleUrls: ['../styles/app.component.less']
})
export class AppComponent implements OnInit {

	PAGE_LOGIN = 1;
	PAGE_HOME = 2;
	PAGE_LOADING = 3;

	public currentPage = null;
	public requestedPage = null;
	public transitioning = false;
	public currentPageClass = null;

	constructor(private service: AppService) {};
	
	ngOnInit(): void {
		this.currentPage = this.PAGE_LOADING;
		
	  	this.service.requestedPage$.subscribe(
			page => {
				this.setCurrentPage(this[page]);
		});
	}

	public setCurrentPage(value: number): void {
		if(this.transitioning || !value) return;
		this.transitioning = true;
		this.requestedPage = value;
		setTimeout(this.changePage, this.service.TRANSITION_SPEED);
	}

	private changePage = () => {
		this.currentPage = this.requestedPage;
		this.requestedPage = null;
		this.transitioning = false;
		this.currentPageClass = this.getCurrentPageClass();
	};

	public getCurrentPageClass() {
		for(let prop in this) {
			if(!prop.match(/^PAGE_/)) continue;
			if(this[prop] !== this.currentPage) continue;
			return "page-" + prop.replace(/^PAGE_/gim,"").replace(/_/gim,"-").toLowerCase();
		}
		return null;
	}
}
